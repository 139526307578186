import { ColorGradient } from '../models';

/**
 * Solid colors to be used for map path-strokes .... 
 */
export const COLORS = [
    '#0D68AF',
    '#ff0048', 
    '#3dc7f4', 
    '#92cc40', 
    '#19C8FF', 
    '#058DC7', 
    '#50B432', 
    '#ED561B', 
    '#DDDF00', 
    '#24CBE5', 
    '#64E572', 
    '#FF9655', 
    '#FFF263', 
    '#6AF9C4'
];

/**
 * Gradient colors to be used for map path-strokes .... 
 */
export const GRADIENT_COLORS: ColorGradient[] = [
    ColorGradient.from([
        /* 5.RdBu&n */
        [0 / 9, '#053061'], 
        [1 / 9, '#2166ac'], 
        [2 / 9, '#4393c3'], 
        [3 / 9, '#92c5de'], 
        [4 / 9, '#d1e5f0'], 
        [5 / 9, '#fddbc7'], 
        [6 / 9, '#f4a582'], 
        [7 / 9, '#d6604d'], 
        [8 / 9, '#b2182b'], 
        [9 / 9, '#67001f']
    ]),
    ColorGradient.from([
        /* 1.BrBG&n */
        [0 / 9, '#543005'], 
        [1 / 9, '#8c510a'], 
        [2 / 9, '#bf812d'], 
        [3 / 9, '#dfc27d'], 
        [4 / 9, '#f6e8c3'], 
        [5 / 9, '#c7eae5'], 
        [6 / 9, '#80cdc1'], 
        [7 / 9, '#35978f'], 
        [8 / 9, '#01665e'], 
        [9 / 9, '#003c30']
    ]),
    ColorGradient.from([
        /* 2.PiYG&n */
        [0 / 9, '#8e0152'], 
        [1 / 9, '#c51b7d'], 
        [2 / 9, '#de77ae'], 
        [3 / 9, '#f1b6da'], 
        [4 / 9, '#fde0ef'], 
        [5 / 9, '#e6f5d0'], 
        [6 / 9, '#b8e186'], 
        [7 / 9, '#7fbc41'], 
        [8 / 9, '#4d9221'], 
        [9 / 9, '#276419']
    ]),
    ColorGradient.from([
        /* 3.PRGn&n */
        [0 / 9, '#40004b'], 
        [1 / 9, '#762a83'], 
        [2 / 9, '#9970ab'], 
        [3 / 9, '#c2a5cf'], 
        [4 / 9, '#e7d4e8'], 
        [5 / 9, '#d9f0d3'], 
        [6 / 9, '#a6dba0'], 
        [7 / 9, '#5aae61'], 
        [8 / 9, '#1b7837'], 
        [9 / 9, '#00441b']
    ]),
    ColorGradient.from([
        /* 4.PuOr&n */
        [0 / 9, '#7f3b08'], 
        [1 / 9, '#b35806'], 
        [2 / 9, '#e08214'], 
        [3 / 9, '#fdb863'], 
        [4 / 9, '#fee0b6'], 
        [5 / 9, '#d8daeb'], 
        [6 / 9, '#b2abd2'], 
        [7 / 9, '#8073ac'], 
        [8 / 9, '#542788'], 
        [9 / 9, '#2d004b']
    ]),
    ColorGradient.from([
        /* 6.RdYlBu&n */
        [0 / 9, '#313695'], 
        [1 / 9, '#4575b4'], 
        [2 / 9, '#74add1'], 
        [3 / 9, '#abd9e9'], 
        [4 / 9, '#e0f3f8'], 
        [5 / 9, '#fee090'], 
        [6 / 9, '#fdae61'], 
        [7 / 9, '#f46d43'], 
        [8 / 9, '#d73027'], 
        [9 / 9, '#a50026']
    ]),
    ColorGradient.from([    
        [0 / 9, '#5DA909'], 
        [1 / 9, '#7BA90E'], 
        [2 / 9, '#9EA815'], 
        [3 / 9, '#BFA71A'], 
        [4 / 9, '#E2A720'], 
        [5 / 9, '#F29523'], 
        [6 / 9, '#E86E20'], 
        [7 / 9, '#E04A1F'], 
        [8 / 9, '#D92A1D'], 
        [9 / 9, '#D0021B']  
    ])  
    //     [0 / 9, '#1ab321'], 
    //     [1 / 9, '#32a11f'], 
    //     [2 / 9, '#4b8f1d'], 
    //     [3 / 9, '#637d1b'], 
    //     [4 / 9, '#7c6b19'], 
    //     [5 / 9, '#945818'], 
    //     [6 / 9, '#ad4616'], 
    //     [7 / 9, '#c53414'], 
    //     [8 / 9, '#de2212'], 
    //     [9 / 9, '#f61010']
    // ])
];
//first
        // [0 / 9, '#67001f'], 
        // [1 / 9, '#b2182b'], 
        // [2 / 9, '#d6604d'], 
        // [3 / 9, '#f4a582'], 
        // [4 / 9, '#fddbc7'], 
        // [5 / 9, '#d1e5f0'], 
        // [6 / 9, '#92c5de'], 
        // [7 / 9, '#4393c3'], 
        // [8 / 9, '#2166ac'], 
        // [9 / 9, '#053061']
//last
        // [0 / 9, '#a50026'], 
        // [1 / 9, '#d73027'], 
        // [2 / 9, '#f46d43'], 
        // [3 / 9, '#fdae61'], 
        // [4 / 9, '#fee090'], 
        // [5 / 9, '#e0f3f8'], 
        // [6 / 9, '#abd9e9'], 
        // [7 / 9, '#74add1'], 
        // [8 / 9, '#4575b4'], 
        // [9 / 9, '#313695']