import { Injectable }       from '@angular/core';
import { LoggerService } from '../services';
 
@Injectable()
export class FileUtil {
 
    constructor(private loggerService :LoggerService) {}
 
    isValidFile(file) {
        if((file.name.endsWith(".csv") || (file.name.endsWith(".txt")))) {
           return true; 
        }
        return false;
        ///return file.name.endsWith(".csv");
    }
 
    getHeaderArray(csvRecordsArr, tokenDelimeter) {        
        let headers = csvRecordsArr[0].split(tokenDelimeter);

        let headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }
 
    validateHeaders(origHeaders, fileHeaaders) {
        if (origHeaders.length != fileHeaaders.length) {
            return false;
        }
 
        var fileHeaderMatchFlag = true;
        for (let j = 0; j < origHeaders.length; j++) {
            if (origHeaders[j] != fileHeaaders[j]) {
                fileHeaderMatchFlag = false;
                break;
            }
        }
        return fileHeaderMatchFlag;
    }
 
    getDataRecordsArrayFromCSVFile(csvRecordsArray, headerLength, 
        validateHeaderAndRecordLengthFlag, tokenDelimeter) {
        var dataArr = []
        
        for (let i = 0; i < csvRecordsArray.length; i++) {
            let data = csvRecordsArray[i].split(tokenDelimeter);
             
            if(validateHeaderAndRecordLengthFlag && data.length != headerLength){
                if(data==""){
                   // alert("Extra blank line is present at line number "+i+", please remove it.");
                   this.loggerService.showErrorMessage("Extra blank line is present at line number "+i+", please remove it.");
                    return null;
                }else{
                    this.loggerService.showErrorMessage("Record at line number "+i+" contain "+data.length+" tokens, and is not matching with header length of :"+headerLength);
                    //alert("Record at line number "+i+" contain "+data.length+" tokens, and is not matching with header length of :"+headerLength);
                    return null;
                }
            }
 
            let col = [];
            for (let j = 0; j < data.length; j++) {
                col.push(data[j]);
            }
            dataArr.push(col);
        }   
        return dataArr;
    }
 
}