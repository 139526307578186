export class Firmware {
    public static readonly Variants = [
        { "Id": 0, "Name": "Default" },
        { "Id": 1, "Name": "Easee Charge" },
        { "Id": 2, "Name": "Easee Home" }
    ];

    public static readonly VariantsReleases = [
        { 
            "Id": 274, 
            "FirmwareMD5": "Q+z4XQs5BndR+1SlhEFE3Q==" ,
            "ReleaseDate" : "2021-01-22T09:24:35.6774589Z",
            "Size": 5500928,
            "Url": "https://masterloop.blob.core.windows.net/firmware-releases/EASEECHG_274_43ECF85D0B39067751FB54A5844144DD.dat",
            "VersionNo": 274,
            "VariantId" : 0
        },
        { 
            "Id": 275, 
            "FirmwareMD5": "ZKHoI6t0i0XwI51dFCzl4A==" ,
            "ReleaseDate" : "2021-01-29T14:51:00.7618354Z",
            "Size": 5500928,
            "Url": "https://masterloop.blob.core.windows.net/firmware-releases/EASEECHG_275_64A1E823AB748B45F0239D5D142CE5E0.dat",
            "VersionNo": 275,
            "VariantId" : 2
        },
        { 
            "Id": 276, 
            "FirmwareMD5": "BgpZhePgIh4CNy3i9UoM5Q==" ,
            "ReleaseDate" : "2021-02-03T09:09:08.9035576Z",
            "Size": 5500928,
            "Url": "https://masterloop.blob.core.windows.net/firmware-releases/EASEECHG_276_060A5985E3E0221E02372DE2F54A0CE5.dat",
            "VersionNo": 276,
            "VariantId" : 1
        },
        { 
            "Id": 277, 
            "FirmwareMD5": "BgpZhePgIh4CNy3i9UoM5Q==" ,
            "ReleaseDate" : "2021-02-03T09:09:08.9035576Z",
            "Size": 5500928,
            "Url": "https://masterloop.blob.core.windows.net/firmware-releases/EASEECHG_276_060A5985E3E0221E02372DE2F54A0CE5.dat",
            "VersionNo": 277,
            "VariantId" : 3
        }
    ];
}