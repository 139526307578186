<mat-card>
  <mat-toolbar class="main-header" color="primary">
    <mat-card-title>
      <h5 style="margin-top:0">Template Settings</h5>
    </mat-card-title>
    <a *ngIf="isView" matTooltip="Add Setting" style="padding-left:25px;" (click)="openAddSettingView()" mat-icon
      role="img" class="material-icons" aria-label="mode edit" id="editsetting">add_circle</a>
  </mat-toolbar>
</mat-card>

<ngx-datatable id='settings' class='material temp-settings striped' [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="60" [rows]='allSettings' [scrollbarH]="true" [scrollbarV]="false">

  <ngx-datatable-column name="ID" prop="Id" [minWidth]="75" [width]="100" [maxWidth]="70">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{value}}</span>
      <input autofocus *ngIf="editing[rowIndex + '-cell']" (blur)="updateValue($event, 'Id', value, row, rowIndex)" type="text"
        [value]="value" id="setId" style="width:50px" />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Name" prop="Name" [minWidth]="75" [width]="150">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{value}}</span>
      <input autofocus *ngIf="editing[rowIndex + '-cell']" (blur)="updateValue($event, 'Name', value, row, rowIndex)"
        type="text" [value]="value" id="setName" style="width:160px" />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Description" prop="Description" [minWidth]="100" [width]="500">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{value}}</span>
      <input autofocus *ngIf="editing[rowIndex + '-cell']" (blur)="updateValue($event, 'Description', value, row, rowIndex)"
        type="text" [value]="value" id="setDescription" style="width:160px" />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Data Type" prop="DataType" [width]="80" [minWidth]="60">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{dataTypes[value]}}</span>
      <select *ngIf="editing[rowIndex + '-cell']" (change)="updateValue($event, 'DataType', value, row, rowIndex)"
        [value]="value" id="setData" style="width:85px">
        <option value="" disabled selected hidden>Select a data type</option>
        <option value="2">Boolean</option>
        <option value="3">Double</option>
        <option value="4">Integer</option>
        <option value="5">Position</option>
        <option value="6">String</option>
      </select>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Unit" prop="UnitName" [width]="80" [minWidth]="60">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{
        value === "Undefined"? "N/A" : value
      }} <span class="abbreviation">{{row.UnitAbbreviation}}</span></span>     
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Default Value" prop="DefaultValue" [width]="80" [minWidth]="60">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">
        <div *ngIf="value.length != 0">{{getDefaultValue(row, value)}}</div>
        <div style="padding:3px; font-size: 12px" *ngIf="value.length == 0" class="menu-badge mat-orange"> Undefined
        </div>
      </span>
      <input autofocus *ngIf="editing[rowIndex + '-cell']" (blur)="updateValue($event, 'DefaultValue', value, row, rowIndex)"
        type="text" [value]="value" id="setVal" style="width:150px" />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name=" " *ngIf="isView" [minWidth]="70" [width]="70">
    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
      <a *ngIf="!isNew" matTooltip="Edit Setting"
        (click)="updateSetting(rowIndex, row)"
        mat-icon role="img" class="material-icons" aria-label="mode edit"
        id="edit">edit</a>
      <a matTooltip="Delete Setting" style="padding-left:25px;" (click)="deleteSettings(row, rowIndex)" mat-icon
        role="img" class="material-icons" aria-label="delete sweep" id="delete">delete</a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
      <div class="custom-footer" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="custom-footer__count">{{ rowCount }}  total</span>
        <a class="custom-footer__add-template" *ngIf="isView" (click)="openAddSettingView()" fxLayout="row" fxLayoutAlign="start center">
          Add Settings
          <span mat-icon role="img" class="material-icons" aria-label="mode edit" id="editsetting">add_circle</span>
        </a>
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
