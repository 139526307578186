<h1 mat-dialog-title>Edit User</h1>
<div mat-dialog-content>
  <form class="edit-user-form popup-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutAlign="space-between start">       
    
      <mat-form-field class="pb-1" fxFlex="100" fxFlex.gt-xs="50">
        <mat-label>First Name</mat-label>
        <input matInput [(ngModel)]="data.firstName" [formControl]="form.controls['firstName']">
      </mat-form-field> 

      <mat-form-field class="pb-1" fxFlex="100" fxFlex.gt-xs="50">
        <mat-label>Last Name</mat-label>
        <input matInput [(ngModel)]="data.lastName" [formControl]="form.controls['lastName']">
      </mat-form-field> 

      <mat-form-field class="pb-1" fxFlex="100" fxFlex.gt-xs="50">
        <mat-label>Account Name</mat-label>
        <input matInput [(ngModel)]="data.eMail" [formControl]="form.controls['eMail']" readonly>
      </mat-form-field>

      <div class="user-edit-checkbox pb-1" fxFlex="100" fxFlex.gt-xs="50">
        <mat-checkbox class="example-margin" [formControl]="form.controls['isAdmin']" [(ngModel)]="data.isAdmin" [checked]="data.isAdmin">Make Admin User</mat-checkbox>
      </div>
    </div>

    <div class="user-edit-btn-wrapper" fxLayout="row" fxLayoutAlign="space-between start">
      <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Save User</button>
      <button mat-raised-button  type="button"  (click)="onNoClick()" style="margin-left:5px">Cancel</button>
    </div>
      
  </form>
</div>