<div class="analyzer" fxLayout="column" fxLayoutAlign="stretch stretch">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h4 class="header">Devices Current Position</h4> 
  
    <div [ngClass]="{'disable-selector-panel':!isSelectorsEnabled}" id="selector-refresh" class="selector-refresh" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="end " fxLayoutAlign.gt-xs="end end">

      <mat-form-field class="device-map-filters">
        <mat-label>Fields to show</mat-label>
        
        <mat-select multiple placeholder="Fields to show" [(ngModel)]="selectedFieldByDefault" [compareWith]="comparer">
          <mat-option (onSelectionChange)="fieldShowChange($event)" *ngFor="let field of fieldList" [value]="field.value">
            {{ field.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="end center">
        <mat-form-field class="device-map-filters">
          <mat-label>Select offline duration</mat-label>
          <mat-select placeholder="Select offline duration" (ngModelChange)="OnDurationSelect($event)" [(ngModel)]="durationId"
            class="device-map-filters">
            <mat-option title='{{duration.id}}' *ngFor="let duration of offlineDurations" [value]="duration.id">
              {{duration.name}} </mat-option>
          </mat-select>
        </mat-form-field>
        <app-reload-content (reload)="plotDevice()"></app-reload-content>
      </div>
    </div>
  </div>

  
  <div class="content" fxLayout="row" fxLayoutAlign="stretch stretch">
    
    <!-- SideNav Options: Start  -->
    <div fxFlex="0 0 30px">
      <div id="options" class="options-toggle"
        [ngClass]="{ 'selected': selectedOptionsTabShowingStatus === OptionsTabShowingStatus.Chosen }"
        (click)="toggleOptionsTabShowingStatus(OptionsTabShowingStatus.Select)">
        <b>Query</b></div>
    </div>
    <!-- SideNav Options: End  -->

    <mat-sidenav-container class="example-sidenav-fab-container" fxFlex="1 1 auto">  
      
      <mat-sidenav #optionsnav mode="over" opened="isOpen">
        <div *ngIf="selectedOptionsTabShowingStatus === OptionsTabShowingStatus.Select" fxLayout="column" style="height: 100%; width: 100%;">
          <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px" style="height: 100%;">
            <div fxFlex style="height: 100%; width: 100%">
              <div class="options">
                <mat-card-content>

                  <div fxLayout fxLayout.xs="column" fxLayoutAlign="left">
                    <h6 style="margin-top: 6px;padding-right: 10px;">Template :</h6>
                    <mat-select class="analyzer-query-inputs" placeholder="" [(ngModel)]="templateId"
                      (ngModelChange)="onTemplateSelect($event)">
                      <mat-option title='{{template.Id}}' *ngFor="let template of templates" [value]="template.Id">
                        {{template.Id}} </mat-option>
                    </mat-select>
                  </div>
                  <br>
                  <mat-card>
                    <mat-toolbar class="main-header" color="primary">
                      <mat-card-title>
                        <h5 style="margin-top:0"> Observations </h5>
                      </mat-card-title>
                    </mat-toolbar>
                  </mat-card>

                  <ngx-datatable class='material current-position striped' [columns]="columns" [columnMode]="'force'" [headerHeight]="50"
                    [rowHeight]="'auto'" [rows]='observations' [scrollbarV]="false" [scrollbarH]="true">

                    <ngx-datatable-column name=" " prop="value" [flexGrow]="1" [width]="50" *ngIf="isMultiple">
                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                        <mat-checkbox title='{{text.ObservationWarning}}' [(ngModel)]="checkedObservations[rowIndex]"
                          (ngModelChange)="onCheckObservationsEvent($event,row, rowIndex)" (value)="value"></mat-checkbox>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Id" prop="Id" [flexGrow]="1" [width]="50" [minWidth]="50"></ngx-datatable-column>

                    <ngx-datatable-column name="Name" prop="Name" [flexGrow]="1" [width]="150" [minWidth]="100"></ngx-datatable-column>

                    <ngx-datatable-column name="DataType" prop="DataType" [flexGrow]="1"
                      [width]="100" [minWidth]="80"></ngx-datatable-column>

                    <ngx-datatable-column name="Description" prop="Description" [flexGrow]="1"
                      [width]="500"></ngx-datatable-column>
                  </ngx-datatable>

                  <button mat-raised-button color="primary" class="mr-1" *ngIf="observations.length > 0"
                    style="margin-left:10px;margin-top:10px;margin-bottom:10px" [disabled]="isLoading"
                    (click)="plotDevice()">Plot</button>

                </mat-card-content>
              </div>
            </div>
          </div>
        </div>
      </mat-sidenav>

      <!-- <div class="popup" *ngIf="isLoading">
        <div class="cssload-speeding-wheel"></div>
      </div> -->
      <div fxFlex="1 1 auto" fxLayoutAlign="stretch stretch" style="height: 100%;">
        <!-- <div id="map"></div> -->
        <div #map class="map analyzer-map">
          <div #popup class="popup-map"></div>
        </div>
      </div>
      <div class="map-legend" style="background-color: rgba(193, 191, 191, 0.5)" fxLayout="row" fxLayoutAlign="start center"
        *ngIf="showlegend">
        <div class="legend-item quarts" *ngFor="let color of legendItems">

          <div>
            <div [style.background-color]='color.color' class="circle" [style.border-color]='color.color'> </div>
            <span> {{color.name}}</span>
          </div>

        </div>
      </div>
    </mat-sidenav-container>
  </div>
</div>