<mat-card>
    <mat-toolbar class="main-header" color="primary">
      <mat-card-title>
        <h5 style="margin-top:0">Template Enumeration Groups</h5>
      </mat-card-title>
      <a
        *ngIf="isView"
        matTooltip="Add Enumeration Groups"
        style="padding-left:25px;"
        (click)="openAddEnumerationView();"
        mat-icon
        role="img"
        class="material-icons"
        aria-label="mode edit"
        id="editEnumerationGroup"
        >add_circle</a
      >
    </mat-toolbar>
  </mat-card>
  
  <ngx-datatable
    id="enumerationGroups"
    class="material enum-table"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="60"
    [rowHeight]="'auto'"
    [rows]="allEnumerationGroups"
    [scrollbarH]="true"
  >
    <ngx-datatable-column
      name="ID"
      prop="Id"
      [minWidth]="50" [width]="50" [maxWidth]="70"
    >
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <span *ngIf="!editing[rowIndex + '-cell']">{{ value }}</span>
        <input
          autofocus
          *ngIf="editing[rowIndex + '-cell']"
          (blur)="updateValue($event, 'Id', value, row, rowIndex)"
          type="text"
          [value]="value"
          id="enumerationGroupsId"
          style="width:50px"
          class="observation-edit-field"
        />
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column name="Enumeration Groups Name" prop="Name" [minWidth]="75" [width]="150">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <span *ngIf="!editing[rowIndex + '-cell']">{{ value }}</span>
        <input
          autofocus
          *ngIf="editing[rowIndex + '-cell']"
          (blur)="updateValue($event, 'Name', value, row, rowIndex)"
          type="text"
          [value]="value"
          id="enumerationGroupName"
          style="width:150px"
          class="observation-edit-field"
        />
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column
      name="Enumeration Items"
      prop="Items"
      [minWidth]="100" [width]="650"
    >
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <span *ngIf="!editing[rowIndex + '-cell']">{{ getEnumerationItemsString(value) }}</span>
        <input
          autofocus
          *ngIf="editing[rowIndex + '-cell']"
          (blur)="updateValue($event, 'Description', value, row, rowIndex)"
          type="text"
          style="width:250px"
          [value]="value"
          id="enumerationItems"
          style="width:200px"
          class="observation-edit-field"
        />
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column
      name=" "
      *ngIf="isView"
      [width]="120" [minWidth]="120"
    >
      <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
        <a
          *ngIf="!isNew"
          matTooltip="Edit Enumeration Group"
          (click)="editEnumerationGroup(rowIndex, row)"
          mat-icon
          role="img"
          class="material-icons"
          aria-label="mode edit"
          id="edit"
          >edit</a
        >
        <a
          matTooltip="Delete Enumeration Group"
          style="padding-left:25px;"
          (click)="deleteEnumerationGroup(row, rowIndex)"
          mat-icon
          role="img"
          class="material-icons"
          aria-label="delete sweep"
          id="delete"
          >delete</a
        >
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
      >
       <div class="custom-footer" fxLayout="row" fxLayoutAlign="space-between center">
          <span class="custom-footer__count">{{ rowCount }}  total</span>
          <a class="custom-footer__add-template" *ngIf="isView" (click)="openAddEnumerationView();" fxLayout="row" fxLayoutAlign="start center">
            Add Enumeration Groups
            <span mat-icon role="img" class="material-icons" aria-label="mode edit" id="editEnumGroup">add_circle</span>
          </a>
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
  