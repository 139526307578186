<div mat-dialog-content>
  <h5>{{title}}</h5>
  <div class="form-container">
    <form [formGroup]="addEditSettingForm" class="template-edit-form">
      <div class="template-edit-form-field-wrapper" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="start " fxLayoutAlign.gt-xs="space-between start">
        <div class="field-columns" fxLayout="column" fxLayoutAlign="start " fxFlex="100%" fxFlex.gt-xs="48%">
          <mat-form-field class="form-field" appearance="fill" fxFlex="100">
            <mat-label> Id
            </mat-label>
            <input matInput formControlName="Id" required>
            <mat-error *ngIf="addEditSettingForm.controls.Id.touched && addEditSettingForm.controls.Id.invalid">
              <span *ngIf="addEditSettingForm.controls.Id.errors.required">This field is mandatory.</span>
            </mat-error>
            <mat-error *ngIf="addEditSettingForm.controls.Id.touched && addEditSettingForm.controls.Id.invalid">
              <span *ngIf="addEditSettingForm.controls.Id.errors['duplicateId']">Settings id is duplicated</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field" appearance="fill" fxFlex="100">
            <mat-label> Name
            </mat-label>
            <input matInput formControlName="Name" required>
            <mat-error *ngIf="addEditSettingForm.controls.Name.touched && addEditSettingForm.controls.Name.invalid">
              <span *ngIf="addEditSettingForm.controls.Name.errors.required">This field is mandatory.</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field" appearance="fill" fxFlex="100">
            <mat-label> Description
            </mat-label>
            <input matInput formControlName="Description" required>
            <mat-error *ngIf="addEditSettingForm.controls.Description.touched && addEditSettingForm.controls.Description.invalid">
              <span *ngIf="addEditSettingForm.controls.Description.errors.required">This field is mandatory.</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field-columns" fxLayout="column" fxLayoutAlign="start " fxFlex="100%" fxFlex.gt-xs="48%">
          <mat-form-field class="form-field" appearance="fill" fxFlex="100">
            <mat-label>Data Types</mat-label>
            <mat-select #datatype formControlName="DataType" (selectionChange)='onDataTypeChanged($event.value)' required>
              <mat-option *ngFor="let dataType of dataTypes" [value]="dataType.id">
                {{ dataType.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="addEditSettingForm.controls.DataType.touched && addEditSettingForm.controls.DataType.invalid">
              <span *ngIf="addEditSettingForm.controls.DataType.errors.required">This field is mandatory.</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="unit-search">
            <mat-label>Unit</mat-label>
            <input matInput formControlName="QuantityUnit" [matAutocomplete]="auto" required>
            <mat-autocomplete #auto="matAutocomplete" class="unit-autocomplete" [displayWith]="displaySelectedOption"
              (optionSelected)="optionSeleted($event)">
              <mat-option *ngFor="let opt of filteredQuanitiyUnitOps | async" [value]="opt">
                {{opt.quantityName}} | {{opt.unitsName}}
              </mat-option>
            </mat-autocomplete>
            <button *ngIf="getClearBtnAvailability()" type="button" mat-button matSuffix mat-icon-button (click)="clearUnitValue()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="addEditSettingForm.controls.QuantityUnit.touched && addEditSettingForm.controls.QuantityUnit.invalid">
              <span *ngIf="addEditSettingForm.controls.QuantityUnit.errors.required">This field is mandatory.</span>
            </mat-error>
            <mat-error
              *ngIf="addEditSettingForm.controls.QuantityUnit.touched && addEditSettingForm.controls.QuantityUnit.invalid">
              <span *ngIf="addEditSettingForm.controls.QuantityUnit.errors['notMatch']">Invalid unit.</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field" appearance="fill" fxFlex="100">
            <mat-label> Default Value
            </mat-label>
            <input *ngIf="!showEnumItemsInDefaultValue" matInput formControlName="DefaultValue" [required]="datatype.value !== 6">
            <mat-select *ngIf="showEnumItemsInDefaultValue" formControlName="DefaultValue" required>
              <mat-option *ngFor="let enumItem of enumItemsOfSelectedEnumGroup" [value]="convertValueToString(enumItem.Id)">
                {{enumItem.Id}} = {{enumItem.Name}} 
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="addEditSettingForm.controls.DefaultValue.touched && addEditSettingForm.controls.DefaultValue.invalid">
              <span *ngIf="addEditSettingForm.controls.DefaultValue.errors.required">This field is mandatory.</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>        
      <div mat-dialog-actions  fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button color="primary" [disabled]="!addEditSettingForm.dirty || addEditSettingForm.invalid" (click)="save()">Save</button>
        <button mat-raised-button (click)="onNoClick()">Cancel</button>
      </div>
    </form>
  </div>
</div>
