export class DataTypeColors {
    static readonly BooleanDark = 'rgba(253, 200, 128, 0.25)';
    static readonly DoubleDark = 'rgba(135, 246, 250, 0.25)';
    static readonly IntegerDark = 'rgba(175, 250, 135, 0.25)';
    static readonly PositionDark = 'rgba(128, 171, 252, 0.25)';
    static readonly StringDark = 'rgba(254, 128, 159, 0.25)';

    static readonly BooleanLight = 'rgba(253, 200, 128, 0.1)';
    static readonly DoubleLight = 'rgba(135, 246, 250, 0.1)';
    static readonly IntegerLight = 'rgba(175, 250, 135, 0.1)';
    static readonly PositionLight = 'rgba(128, 171, 252, 0.1)';
    static readonly StringLight = 'rgba(254, 128, 159, 0.1)';

}
