<div class="session mat-mcs-blue">
  <div class="session-content">
    <div class="session-wrapper">
      <mat-card>
        <mat-card-content>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="text-xs-center pb-1">
              <img src="assets/images/logo-dark.png" alt="" class="light-icon login-logo" width="150"/>
              <img src="assets/images/Masterloop_Logo_Inverse.png" alt="" class="dark-icon login-logo" width="150"/>
              <p class="mat-text-muted">
                Sign in with your app ID to continue.
              </p>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-around">
              <mat-form-field class="pb-1">
                <input
                  matInput
                  placeholder="Username"
                  [formControl]="form.controls['username']"
                  style="width: 100%"
                  id="username"
                />
                <mat-error
                  *ngIf="
                    form.controls['username'].hasError('required') &&
                    form.controls['username'].touched
                  "
                  class="mat-text-warn"
                  >Username is required.</mat-error
                >
              </mat-form-field>
              <mat-form-field class="pb-1">
                <input
                  matInput
                  type="password"
                  placeholder="Password"
                  [formControl]="form.controls['password']"
                  style="width: 100%"
                  id="password"
                />
                <mat-error
                  *ngIf="
                    form.controls['password'].hasError('required') &&
                    form.controls['password'].touched
                  "
                  class="mat-text-warn"
                  >Password is required.</mat-error
                >
              </mat-form-field>
              <mat-error *ngIf="form.hasError('')" class="mat-text-warn">{{
                form.errors[""]
              }}</mat-error>
              <button
                mat-raised-button
                color="primary"
                type="submit"
                id="submitBtn"
              >
                Login
              </button>
            </div>
            <div class="pt-1 pb-1 text-xs-center">
              <a [routerLink]="['../forgot']">Forgot password?</a>
              <!--<a [routerLink]="['/session/signup']">Sign up for a new account.</a>-->
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
