/**
 * Tile layer types that can be overlayed on OpenLayer maps. 
 */
export enum MapLayer {
    OsmDefault,
    OsmOpenSea,
    CartoLight,
    CartoDark,
    BingAerial,
    BingAerialWithLabels
}