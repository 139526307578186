/**
 * Observation data types.
 */
export enum ObservationDataType {
    Unknown = 0,
    Binary = 1,
    Boolean = 2,
    Double = 3,
    Integer = 4,
    Position = 5,
    String = 6,
    Statistics = 7
}