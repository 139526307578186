<mat-card>
  <mat-toolbar class="main-header" color="primary">
    <mat-card-title>
      <h5 style="margin-top:0">Template Observations</h5>
    </mat-card-title>
    <a
      *ngIf="isView"
      matTooltip="Add Observation"
      style="padding-left:25px;"
      (click)="openAddObservationView();"
      mat-icon
      role="img"
      class="material-icons"
      aria-label="mode edit"
      id="editObs"
      >add_circle</a
    >
  </mat-toolbar>
</mat-card>

<ngx-datatable id="observations" class="material temp-obs striped" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="65" [rows]="updatedObservations" [scrollbarH]="true" [scrollbarV]="false">
  <ngx-datatable-column name="ID" prop="Id" [minWidth]="50" [width]="50" [maxWidth]="70">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{ value }}</span>
      <input
        autofocus
        *ngIf="editing[rowIndex + '-cell']"
        (blur)="updateValue($event, 'Id', value, row, rowIndex)"
        type="text"
        [value]="value"
        id="obsId"
        style="width:50px"
      />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Name" prop="Name" [minWidth]="75" [width]="150">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{ value }}</span>
      <input
        autofocus
        *ngIf="editing[rowIndex + '-cell']"
        (blur)="updateValue($event, 'Name', value, row, rowIndex)"
        type="text"
        [value]="value"
        id="obsName"
        style="width:160px;"
      />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Description" prop="Description" [minWidth]="100" [width]="650">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{ value }}</span>
      <input
        autofocus
        *ngIf="editing[rowIndex + '-cell']"
        (blur)="updateValue($event, 'Description', value, row, rowIndex)"
        type="text"
        style="width:300px"
        [value]="value"
        id="obsDes"
      />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Data Type" prop="DataType" [width]="80" [minWidth]="60">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{
        dataTypes[value]
      }}</span>
      <select
        *ngIf="editing[rowIndex + '-cell']"
        (change)="updateValue($event, 'DataType', value, row, rowIndex)"
        [value]="value"
        id="obsData"
        style="width:85px"
      >
        <option value="" disabled selected hidden>Select a data type</option>
        <option value="1">Binary</option>
        <option value="2">Boolean</option>
        <option value="3">Double</option>
        <option value="4">Integer</option>
        <option value="5">Position</option>
        <option value="6">String</option>
        <option value="7">Statistics</option>
      </select>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Unit" prop="UnitName" [width]="80" [minWidth]="60">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{
        value === "Undefined"? "N/A" : value
      }} <span class="abbreviation">{{row.UnitAbbreviation}}</span></span>     
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name=" "
    *ngIf="isView"
    [width]="70"
    [minWidth]="70"
    [flexGrow]="0.75"
  >
    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
      <a
        *ngIf="!isNew"
        matTooltip="Edit Observation"        
        (click)="editObservation(rowIndex, row)"
        mat-icon
        role="img"
        class="material-icons"
        aria-label="mode edit"
        id="edit"
        >edit</a
      >
      <a
      matTooltip="Delete Observation"
        style="padding-left:25px;"
        (click)="deleteObservation(row, rowIndex)"
        mat-icon
        role="img"
        class="material-icons"
        aria-label="delete sweep"
        id="delete"
        >delete</a
      >
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
     <div class="custom-footer" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="custom-footer__count">{{ rowCount }}  total</span>
        <a class="custom-footer__add-template" *ngIf="isView" (click)="openAddObservationView()" fxLayout="row" fxLayoutAlign="start center">
          Add Observations
          <span mat-icon role="img" class="material-icons" aria-label="mode edit" id="editObs">add_circle</span>
        </a>
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
