export const darkTheme = {
    chart: {
        backgroundColor: '#353535'
    },
    xAxis: {
        labels: {
            style: {
                color: '#A0A0A3',
            },
        },
        title: {
            style: {
                color: '#A0A0A3',
            },
        },
    },
    yAxis: {
        gridLineColor: '#606062',
        labels: {
            style: {
                color: '#A0A0A3',
            },
        },
        title: {
            style: {
                color: '#A0A0A3',
            },
        },
    },
    legend: {
        itemStyle: {
            color: '#FFFFFF',
        },
    }
};

export const lightTheme = {
    chart: {
        backgroundColor: '#FAFAFA'
    },
    xAxis: {
        labels: {
            style: {
                color: '#333333',
            },
        },
        title: {
            style: {
                color: '#333333',
            },
        },
    },
    yAxis: {
        gridLineColor: '#f0f0f0',
        labels: {
            style: {
                color: '#333333',
            },
        },
        title: {
            style: {
                color: '#333333',
            },
        },
    },
    legend: {
        itemStyle: {
            color: '#333333',
        },
    },
};