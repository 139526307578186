<mat-card>
  <mat-toolbar class="main-header" color="primary">
    <mat-card-title>
      <h5 style="margin-top:0">Template Pulses</h5>
    </mat-card-title>
    <a
      *ngIf="isView"
      matTooltip="Add Pulses"
      style="padding-left:25px;"
      (click)="addPulses(); scrollDown()"
      mat-icon
      role="img"
      class="material-icons"
      aria-label="mode edit"
      id="editpulse"
      >add_circle</a
    >
  </mat-toolbar>
</mat-card>

<ngx-datatable
  id="pulses"
  class="material temp-pulses"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="60"
  [rowHeight]="'auto'"
  [rows]="allPulses"
  [scrollbarH]="true"
>
  <ngx-datatable-column
    name="ID"
    prop="Id"
    [minWidth]="50" [width]="50" [maxWidth]="70"
  >
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{ value }}</span>
      <input
        autofocus
        *ngIf="editing[rowIndex + '-cell']"
        (blur)="updateValue($event, 'Id', value, row, rowIndex)"
        type="text"
        [value]="value"
        id="pulseId"
        style="width:50px"
        class="observation-edit-field"
      />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Name" prop="Name" [minWidth]="75" [width]="150">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{ value }}</span>
      <input
        autofocus
        *ngIf="editing[rowIndex + '-cell']"
        (blur)="updateValue($event, 'Name', value, row, rowIndex)"
        type="text"
        [value]="value"
        id="pulseName"
        style="width:150px"
        class="observation-edit-field"
      />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Description"
    prop="Description"
    [minWidth]="100" [width]="650"
  >
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{ value }}</span>
      <input
        autofocus
        *ngIf="editing[rowIndex + '-cell']"
        (blur)="updateValue($event, 'Description', value, row, rowIndex)"
        type="text"
        style="width:250px"
        [value]="value"
        id="pulseDes"
        style="width:200px"
        class="observation-edit-field"
      />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Max Absence (s)"
    prop="MaximumAbsence"
    [width]="250" [minWidth]="150"
  >
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <span *ngIf="!editing[rowIndex + '-cell']">{{ value }}</span>
      <input
        autofocus
        *ngIf="editing[rowIndex + '-cell']"
        (blur)="updateValue($event, 'MaximumAbsence', value, row, rowIndex)"
        type="text"
        style="width:100px"
        [value]="value"
        id="pulseAbs"
        style="width:100px"
        class="observation-edit-field"
      />
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name=" "
    *ngIf="isView"
    [width]="120" [minWidth]="120"
  >
    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
      <a
        *ngIf="!isNew"
        matTooltip="Edit Pulse"
        (click)="editing[rowIndex + '-cell'] = true"
        mat-icon
        role="img"
        class="material-icons"
        aria-label="mode edit"
        id="edit"
        >edit</a
      >
      <a
        matTooltip="Delete Pulse"
        style="padding-left:25px;"
        (click)="deletePulse(row, rowIndex)"
        mat-icon
        role="img"
        class="material-icons"
        aria-label="delete sweep"
        id="delete"
        >delete</a
      >
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
     <div class="custom-footer" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="custom-footer__count">{{ rowCount }}  total</span>
        <a class="custom-footer__add-template" *ngIf="isView" (click)="addPulses()" fxLayout="row" fxLayoutAlign="start center">
          Add Pulses
          <span mat-icon role="img" class="material-icons" aria-label="mode edit" id="editpulse">add_circle</span>
        </a>
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
