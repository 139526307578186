<!-- <div class="export">
  <form #userForm="ngForm" (ngSubmit)="onFormSubmit(userForm)">
    <mat-select name="type">
      <mat-option *ngFor="let type of types" [value]="type.id" id="tenant">{{
        type.name
      }}</mat-option>
    </mat-select>
    <button mat-flat-button>Basic</button>
    <button mat-icon-button>
      <mat-icon>file_download</mat-icon>
    </button> -->
    <!-- <button>Export</button>
  </form>
</div> -->

<div class="export"> 
  <mat-form-field appearance="fill">
    <mat-label>Download as C# or C</mat-label>
    <mat-select name="type" (selectionChange)="onTypeChange($event)">
      <mat-option *ngFor="let type of types" [value]="type.id" >{{
        type.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
    
</div>
