<div class="session mat-mcs-blue">
  <div class="session-content">
    <div class="session-wrapper">
      <mat-card>
        <mat-card-content>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="text-xs-center pb-1">
              <img src="assets/images/logo-dark.png" alt="" class="light-icon login-logo" width="150" />
              <img src="assets/images/Masterloop_Logo_Inverse.png" alt="" class="dark-icon login-logo" width="150" />
              <p class="mat-text-muted">
                Enter your email and we'll send you instructions on how to reset
                your password.
              </p>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-around">
              <mat-form-field class="pb-1">
                <input
                  matInput
                  placeholder="Email address"
                  type="email"
                  [formControl]="form.controls['email']"
                  style="width: 100%"
                />
                <mat-error
                  *ngIf="
                    form.controls['email'].hasError('required') &&
                    form.controls['email'].touched
                  "
                  class="mat-text-warn"
                  >You must include an email address.</mat-error
                >
                <mat-error
                  *ngIf="
                    form.controls['email'].errors?.email &&
                    form.controls['email'].touched
                  "
                  class="mat-text-warn"
                  >You must include a valid email address.</mat-error
                >
              </mat-form-field>
              <button
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="!form.valid"
              >
                Login
              </button>
            </div>
            <div class="pt-1 pb-1 text-xs-center">
              <a [routerLink]="['../signin']">Sign into your account</a>
              <!--<a [routerLink]="['/session/signup']">Sign up for a new account</a>-->
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
